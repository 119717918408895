import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../../store";
import { GetAPIVersion, UpdateLogin, Logout } from "../../../actions";
import { Link } from "react-router-dom";
import { faBold, faFlagUsa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import cjcLogo from "../images/ifcjclogo.jfif";
import ifLogo from "../../../images/ifcjclogo.jfif";
import AdBox from "../../adbox";

const Footer = () => {
  const { APIVersion, dispatch, IsLogin } = useContext(StoreContext);

  useEffect(() => {
    GetAPIVersion(dispatch);
  }, []);

  const handleLogout = () => {
    Logout(dispatch);
  };

  const handleLogin = () => {
    //UpdateLogin(dispatch, true);
  };
  // useEffect(() => {
  //   console.log("is login" + IsLogin);
  // }, IsLogin);

  return (
    <div className="footer-container mt-3">
      
      <div style={{marginBottom:'5px'}}>
      <h4 style={{color:'gray', textAlign:'center', fontFamily:'verdana'}}>Sponsors:</h4>
        {IsLogin ? '':<AdBox></AdBox>}
      </div>
      
      <div className="text-center">
        <img
              alt=""
              src={ifLogo}
              width="200px"
              height="auto"
              className="d-inline-block align-top"
            />
  </div>

      <div className="mt-3 rectangle text-center">
        <p>
          Central Jersey Combine <br></br>
          <span>Copyright © {new Date().getFullYear()}</span>
          {/* 20 Stuyvesant Avenue Lyndhurst, NJ 07071<br></br>
          Phone 201-933-3764 */}
        </p>

        <p onClick={() => handleLogin()}>
          <Link to="/admin" style={{cursor:'default'}}>
            <FontAwesomeIcon icon={faFlagUsa} color="gray" />
          </Link>
        </p>

        <p className="pigeon-connect">
          <a href="https://www.charmedcoder.com" target="_blank" style={{'fontSize':'12px',color:'#CD5C5C'}}>
            {" "}
            Need a website or app? Contact Chris @<b>CharmedCoder</b>
          </a>
        </p>
        
      </div>
      <div className="login-div"></div>

      <div className="api-version">{(APIVersion ==0)? 'api unavailable' : `api V${APIVersion}`} - app V3.8</div>
      
    </div>
  );
};
export default Footer;
